import { 
  FacebookShareButton, 
  FacebookIcon, 
  WhatsappShareButton, 
  WhatsappIcon, 
  TwitterShareButton, 
  TwitterIcon, 
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share'
import { Container } from './styles'
import { usePartner } from '~/contexts'
import { Dropdown } from '../Dropdown'
import { Button, Card, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IconShare } from '@tabler/icons-react'
import { useCallback, useState } from 'react'

export const ShareSocialMediaLinks: React.FC = () => {
  const { indicationLink, myPartner } = usePartner()
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [share, setShare] = useState<string | null>(null)

  const widthBox = useCallback(() => {
    if(isLowerSm) {
      if(share) {
        return {
          left: '0',
          top: '60px'
        }
      } else {
        return {
           left: '0',
          top: '-20px'
        }
      }
    }
    else {
      if(share) {
        return {
          left: '180px',
          top: '6px'
        }
      } else {
        return {
          left: '180px',
          top: '-40px'
        }
      }
    }
  }, [share, isLowerSm])

  const width = widthBox()

  return (
    <Dropdown
      customClose={() => setShare(null)}
      $options={{ left: width.left, top: width.top }} 
      elementClick={<Button sx={{ boxSizing: 'content-box' }} endIcon={<IconShare size={24} />} 
      color='secondary'
      >
        Compartlihar
        </Button>
      }
    >
 
      {Boolean(share) ? (
        <Container>
          <FacebookShareButton url={share!}>
            <FacebookIcon size={32} round={true}  />
          </FacebookShareButton>
          <WhatsappShareButton url={share!}>
            <WhatsappIcon size={32} round={true}  />
          </WhatsappShareButton>
          <TwitterShareButton url={share!}>
            <TwitterIcon size={32} round={true}  />
          </TwitterShareButton>
          <TelegramShareButton url={share!}>
            <TelegramIcon size={32} round={true}  />
          </TelegramShareButton>
          <LinkedinShareButton url={`https://www.linkedin.com/sharing/share-offsite/?url=${share}`}>
            <LinkedinIcon size={32} round={true}  />
          </LinkedinShareButton>
       </Container>
      ) : (
      <Card sx={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: '8px', display: 'flex', flexFlow: 'column' }}>
        <MenuItem onClick={() => setShare(`${process.env.VITE_APP_WEBSITE_BASE_URL}/solicitar-proposta/${myPartner?.code}`)} sx={{ fontSize: '.9rem', '&:hover': { background: theme.palette.primary.light } }}>Compartilhar link da página completa</MenuItem>
        <MenuItem onClick={() => setShare(indicationLink)} sx={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-start', '&:hover': { background: theme.palette.primary.light } }}>
          <Typography sx={{ fontSize: '.9rem' }}>Compartilhar link da página reduzida</Typography>
          <Typography sx={{ fontSize: '.8rem' }}>(apenas formulário)</Typography>
        </MenuItem>
      </Card>
      )}
     
    </Dropdown>
  )
}
