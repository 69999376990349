
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { IconCopy, IconDots, IconDownload, IconLink, IconPlus, IconSearch } from '@tabler/icons-react'
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Tooltip, useMediaQuery } from '@mui/material'
import { formatDate, theme, translateIndicationsOriginStatus, translateIndicationsStatus } from '~/utils'
import { SearchQueysData, useDebounce, useQueryString, useToggle } from '~/hooks'
import { Card, ContentTitle, OriginStatus, IndicationsStatus, TableHeaderApi, Dropdown, TableApiV2, FilterButton } from '~/components'
import { useNavigate } from 'react-router-dom'
import { EIndicationStatus, useGetIndicationsCsvLazyQuery, useGetIndicationsQuery } from '~/graphql/types'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import { usePartner } from '~/contexts/partner'
import { copyToClipboard } from '~/utils/clipboard'
import { StyledA, StyledLink } from './styles'
import { canDownloadProposal } from './utils'
import { FilterIndicationDrawer } from './components/FilterIndicationDrawer'
import { useAuth } from '~/contexts'

export const Indications: React.FC = () => {
  const navigate = useNavigate()
  const { authUser } = useAuth()
  const { myPartner, indicationLink } = usePartner()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))
  const ref = useRef<any>(null)
  const isOwner = authUser?.owner

  const queryKeys: SearchQueysData[] = [
    { key: 'name', defaultValue: '' },
    { key: 'status', defaultValue: [], parseValue: true },
    { key: 'origin', defaultValue: [], parseValue: true },
    { key: 'begin', defaultValue: '' },
    { key: 'end', defaultValue: '' },
  ]

  const { page, size, setSearchParams, searchQuerys, paginationQuerySize, searchParams } = useQueryString()

  const queryValues = searchQuerys(queryKeys)

  const [filterName, setFilterName] = useState(queryValues.name)

  const debouncedFilterName = useDebounce(filterName, 1000)

  const { isTrue: drawerIsVisible, toggle: toggleDrawer, setIsTrue: setDrawerIsVisible } = useToggle(false)

  useEffect(() => {
    setSearchParams(state => {
      if (debouncedFilterName) {
        state.set('name', debouncedFilterName)
        state.set('page', '0')
      } else {
        state.delete('name')
      }
      return state
    })
  }, [debouncedFilterName])

  const { data, loading: indicationsListIsLoading } = useGetIndicationsQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        filter: {
          ...queryValues?.name?.length > 0 ? { nameCustomerOrSalesman: queryValues.name } : {},
          ...queryValues?.status?.length > 0 ? { status: queryValues.status } : {},
          ...queryValues?.origin?.length > 0 ? { origin: queryValues.origin } : {},
          ...queryValues?.begin?.length > 0 ? { begin: new Date(queryValues.begin) } : {},
          ...queryValues?.end?.length > 0 ? { end: new Date(`${queryValues.end}Z23:59:59`) } : {},
        },
        sort: {
          field: 'createdAt',
          order: -1
        }
      }
    },
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(result) {
      setSearchParams(state => {
        state.set('items', String(result.getIndications.meta.documentCounts))
        return state
      })
    },
    fetchPolicy: 'no-cache'
  })

  const [getAllIndicationsCsv, { data: allIndicationsCsv, loading: allIndicationsIsLoading }] = useGetIndicationsCsvLazyQuery({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted() {
      ref?.current?.link?.click()
    }
  })

  const onFetchCSV = useCallback(() => {
    getAllIndicationsCsv({
      variables: {
        params: {
          pageSize: 999999999,
          filter: {
            ...queryValues?.begin?.length > 0 ? { begin: new Date(queryValues.begin) } : {},
            ...queryValues?.end?.length > 0 ? { end: new Date(`${queryValues.end}Z23:59:59`) } : {},
          },
          sort: {
            field: 'createdAt',
            order: -1
          }
        }
      }
    })
  }, [queryValues])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Cliente',
        accessor: 'customerRef.name',
        Cell: ({ value }) => <Tooltip title={value}>
          <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%', maxWidth: '225px' }}>
            {value}
          </div>
        </Tooltip>,

        // <Tooltip title={value}><div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}>{value}</div></Tooltip>
      },
      {
        Header: <div style={{ textAlign: 'center' }}>Origem</div>,
        accessor: 'origin',
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}><OriginStatus status={value} /></div>
        },
        minWidth: isLowerLg ? undefined : 80,
      },
      {
        Header: <div style={{ textAlign: 'center' }}>Vendedor</div>,
        accessor: 'salesmanRef.name',
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>
        },
        minWidth: isLowerLg ? undefined : 100,
      },
      {
        Header: <div style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Criado em</div>,
        accessor: 'createdAt',
        Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{formatDate(value)}</div>,
        minWidth: isLowerLg ? undefined : 160,
      },
      {
        Header: <div style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Atualizado em</div>,
        accessor: 'updatedAt',
        Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{formatDate(value)}</div>,
        minWidth: isLowerLg ? undefined : 160,
      },
      {
        Header: <div style={{ textAlign: 'center' }}>Status</div>,
        accessor: 'status',

        Cell: ({ value, row }: any) => <div style={{ textAlign: 'center' }}><IndicationsStatus errorMessage={row.original?.errorMessage} status={value} /></div>,
        minWidth: isLowerLg ? undefined : 150,
      },
      {
        Header: 'Ações',
        accessor: '_id',
        Cell: ({ value, row }: any) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {canDownloadProposal(row.original.status) || row.original.status === EIndicationStatus.requestedProposal ? (
                <Dropdown $options={{ left: '-118px' }} elementClick={<IconButton><IconDots /></IconButton>}>
                  <Card sx={{ width: '100%', padding: '1rem', display: 'flex', flexFlow: 'column', gap: '.4rem' }}>
                    {canDownloadProposal(row.original.status) && <StyledA onClick={() => window.open(`${process.env.VITE_APP_REST_API_URL}files/download/file/${row.original.metadata.preProposalFileKey}`)}>Baixar Proposta</StyledA>}
                    {row.original.status === EIndicationStatus.requestedProposal && <StyledLink to={`/app/indications/update/${value}`}>Atualizar proposta</StyledLink>}
                  </Card>
                </Dropdown>
              ) :
                <IconButton><IconDots /></IconButton>
              }
            </Box>

          )
        },
        minWidth: isLowerLg ? undefined : 70,
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.getIndications.data || [], [data])

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: isOwner ? [] : ['salesmanRef.name']
    },
    defaultColumn: {
      Filter: <></>,
    },
  },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  return (
    <Card>
      <ContentTitle
        title='Todas Indicações'
        style={{ padding: '0px 0px 1.6rem 0px' }}
        description='Acompanhe os indicados e solicite proposta pra gerar indicação'
        breadcrumbLinks={{ currentLink: 'Todas indicações', links: [{ href: '/app/indications', label: 'Área do Afiliado' }] }}
        rightContent={(
          <Box sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'end',
            gap: isLowerSm ? 1 : 3,
            flexFlow: isLowerSm ? 'column' : 'row',
            alignItems: isLowerSm ? 'none' : 'center'
          }}>
            {myPartner?.configuration.allowAffiliateLink && (
              <Button
                color='tertiary'
                variant='outlined'
                startIcon={<IconLink size={24} color='#CDCDCD' />}
                endIcon={<IconCopy size={24} color='#F3CC04' />}
                onClick={() => copyToClipboard(indicationLink)}
              >
                Link de indicação
              </Button>
            )}
            {myPartner?.configuration.allowManualProposal && (
              <Button
                endIcon={<IconPlus size={24} />}
                onClick={() => navigate('/app/indications/create')}
              >
                Solicitar proposta
              </Button>
            )}
          </Box>
        )}
      />

      <TableHeaderApi
        startElement={(
          <>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder={isOwner ? 'Busque por nome do cliente ou vendedor' : 'Busque por um cliente'}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {isOwner && (
                  <>
                    <Button
                      onClick={onFetchCSV}
                      color='secondary'
                      disabled={allIndicationsIsLoading}
                      startIcon={allIndicationsIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconDownload size={24} />}
                    >
                      {allIndicationsIsLoading ? 'exportando' : 'Exportar'}
                    </Button>
                    <CSVLink
                      style={{ visibility: `hidden` }}
                      filename='desperta-energia-indicações.csv'
                      data={allIndicationsCsv ? allIndicationsCsv?.getIndications.data.map(item => ({
                        data: formatDate(item.createdAt),
                        status: translateIndicationsStatus(item.status),
                        vendedor: item.salesmanRef.name,
                        origem: translateIndicationsOriginStatus(item.origin),
                        estado: item?.condition?.uf,
                        concessionária: item?.condition?.dealershipName,
                        'comissão do parceiro': `${item?.condition?.baseCommissionPercentage}%`,
                        'comissão adicional': `${item?.condition?.additionalCommissionPercentage}%`,
                        comissão: `${item?.condition?.commissionAmountPercentage}%`,
                        'Motivo da recusa': item.errorMessage
                      })) : []}
                      ref={ref}
                    >

                    </CSVLink>
                  </>
                )}

                <FilterButton onCustomClear={() => setFilterName('')} filtersToClear={queryKeys} toggleDrawer={toggleDrawer} filterCounter={searchParams.size - paginationQuerySize} />
              </Box>

              <FilterIndicationDrawer queryKeys={queryKeys} drawerIsOpen={drawerIsVisible} setDrawerIsOpen={setDrawerIsVisible} toggleDrawer={toggleDrawer} />
            </Box>
          </>
        )}
      />
      <TableApiV2
        // tableLayout={isLowerSm ? 'auto' : 'fixed'}
        columns={columns}
        tableMethods={tableMethods}
        isLoading={indicationsListIsLoading}
      />
    </Card>
  )
}
