/* eslint-disable no-unused-vars */
import { EAffiliatePartnerUserStatus, ECommissionStatus, EIndicationOrigin, EIndicationStatus, EProposalStatus } from '~/graphql/types'

export const pickMonthNameByNumber = (value: number, type: 'short' | 'long' = 'short'): string => {

  const MONTH_NUMBER: { [x in number]: string } = {
    1: type ==='short' ? 'Jan' : 'Janeiro',
    2: type ==='short' ? 'Fev' : 'Fevereiro',
    3: type ==='short' ? 'Mar' : 'Março',
    4: type ==='short' ? 'Abr' : 'Abril',
    5: type ==='short' ? 'Mai' : 'Maio',
    6: type ==='short' ? 'Jun' : 'Junho',
    7: type ==='short' ? 'Jul' : 'Julho',
    8: type ==='short' ? 'Ago' : 'Agosto',
    9: type ==='short' ? 'Set' : 'Setembro',
    10: type ==='short' ? 'Out' : 'Outubro',
    11: type ==='short' ? 'Nov' : 'Novembro',
    12: type ==='short' ? 'Dez' : 'Dezembro',
  }

  return MONTH_NUMBER[value] ?? value
}

const DIGITAL_ACCOUNT_STATUS = {
  inactive: 'Inativo',
  active: 'Ativo',
  pending: 'Pendente',
}

export const translateUserStatus = (status: EAffiliatePartnerUserStatus) => {
  return DIGITAL_ACCOUNT_STATUS[status] ?? status
}

const REFERRALS_STATUS: { [x in EIndicationStatus]: string } = {
  activeContract: 'Contrato ativo',
  allocateDealership: 'Usina alocada',
  canceled: 'Cancelado',
  canceledContract: 'Contrato cancelado',
  canceledProposal: 'Proposta cancelada',
  expiredProposal: 'Proposta expirada',
  pending: 'Pendente',
  requestedProposal: 'Proposta solicitada',
  signedContract: 'Contrato assinado',
}

export const translateIndicationsStatus = (status: EIndicationStatus) => {
  return REFERRALS_STATUS[status] ?? status
}

const INDICATIONS_ORIGINS_STATUS: { [x in EIndicationOrigin]: string } = {
  api: 'Integração api',
  link: 'Link de indicação',
  partnerArea: 'Proposta manual',
}

export const translateIndicationsOriginStatus = (status: EIndicationOrigin) => {
  return INDICATIONS_ORIGINS_STATUS[status] ?? status
}

const COMISSIONS_STATUS: { [x in ECommissionStatus]: string } = {
  canceled: 'Cancelado',
  extension: 'Prorrogado',
  late: 'Atrasado',
  notPaid: 'Não Pago',
  paid: 'Pago'
}

export const translateComissionsStatus = (status: ECommissionStatus) => {
  return COMISSIONS_STATUS[status] ?? status
}

const INDICATION_STATUS_DESCRIPTION: { [x in EIndicationStatus]: string } = {
  canceled: '',
  pending: 'Sua indicação ainda não virou proposta',
  canceledContract: 'Sua indicação foi aceita',
  activeContract: 'Confirmado pela concessionária ',
  allocateDealership: 'Contrato enviado para a concessionária',
  signedContract: 'Aguardando alocação',
  expiredProposal: 'Sua indicação não virou proposta',
  requestedProposal: 'Sua indicação foi aceita',
  canceledProposal: 'Sua indicação não virou proposta',
}

export const descriptionByIndicationStatus = (status: EIndicationStatus) => {
  return INDICATION_STATUS_DESCRIPTION[status] ?? status
}

const PROPOSAL_STATUS: { [x in EProposalStatus]: string } = {
  documentation: 'Documentação',
  formalization: 'Formalização',
  finalized: 'Contratado',
  canceled: 'Cancelado'
}

export function translateProposalStatus(status: EProposalStatus) {
  return PROPOSAL_STATUS[status] ?? status
}
