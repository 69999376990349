import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material'
import { IconArrowLeft, IconCheck, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { useCallback, useEffect } from 'react'
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { DragAndDrop, InfoBanner, Input, Select, confirmDialog } from '~/components'
import { brazilStates } from '~/constants'
import { useCep, useUploadMultiFilesWithNoProposal } from '~/hooks'
import { addressSchema, documentSchema, fileSchema, phoneSchema } from '~/utils/yupSchema'
import * as yup from 'yup'
import { useIndicationFlow } from '~/contexts'
import { LEGAL_REPRESENTATIVE_INITIAL_VALUES, LegalRepresentativeType, LegalRepresentativyPendency, documentFileIsDisabled, hasDuplicatedValuesInLegalRepresentativies, hasPendencyOrNullOnLegalRepresentativy } from '../utils'
import { maritalStatusOptions } from '~/utils/options'
import { EProposalFileStatus, EProposalFileType, useUpdateIndicationProposalFilesMutation, useUpdateIndicationProposalUpdateLegalRepresentativeMutation } from '~/graphql/types'
import { clearString } from '~/utils'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

type FormData = {
  legalRepresentativies: LegalRepresentativeType[]
}

const schema = yup
  .object({
    legalRepresentativies: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        document: documentSchema.required(),
        identificationNumber: yup.string().required(),
        email: yup.string().email().required(),
        phone: phoneSchema.required(),
        nationality: yup.string().required(),
        maritalStatus: yup.string().required(),
        address: addressSchema,
        customerPjRepresentativeDocumentWithPhotoBack: fileSchema,
        customerPjRepresentativeDocumentWithPhotoFront: fileSchema,
      }).required()
    ).required()

  })
  .required()

export const LegalRepresentativies: React.FC = () => {
  const theme = useTheme()
  const { selectedProposal, selectedIndication, setSelectedProposal } = useIndicationFlow()
  const [uploadMultiFiles, { loading: loadingFilesUpload }] = useUploadMultiFilesWithNoProposal()
  const [updateIndicationFiles, { loading: updateIndicationFilesIsLoading }] = useUpdateIndicationProposalFilesMutation()
  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'legalRepresentativies'
  })

  const { legalRepresentativies: legalRepresentativiesWatch } = useWatch({ control: formMethods.control })
  const { isLoading: fetchCepIsLoading, fetchCep } = useCep({ showErrorMessage: true })

  let hasEqualField = false

  legalRepresentativiesWatch?.forEach((representativy, i) => {
    if (clearString(representativy?.document || '').length > 0 && legalRepresentativiesWatch
      .some((item, index) => clearString(item?.document || '') === clearString(representativy?.document || '') && i !== index
      )) {
      hasEqualField = true
    }

    if (representativy.email && representativy?.email?.length > 0 && legalRepresentativiesWatch
      .some((item, index) => item.email === representativy.email
        && i !== index)) {
      hasEqualField = true
    }

    if (clearString(representativy.phone || '').length !== 0 && legalRepresentativiesWatch
      .some((item, index) => clearString(item.phone || '') === clearString(representativy.phone || '')
        && i !== index)) {
      hasEqualField = true
    }

    if (representativy.identificationNumber && representativy.identificationNumber.length !== 0 && legalRepresentativiesWatch
      .some((item, index) => item.identificationNumber === representativy.identificationNumber
        && i !== index)) {
      hasEqualField = true
    }
  })

  const enableSubmit = legalRepresentativiesWatch?.length !== selectedProposal?.customerRef?.legalRepresentative?.length ||
    legalRepresentativiesWatch?.some(item =>
      item.customerPjRepresentativeDocumentWithPhotoBack && item.customerPjRepresentativeDocumentWithPhotoBack?.length > 0 ||
      item.customerPjRepresentativeDocumentWithPhotoFront && item.customerPjRepresentativeDocumentWithPhotoFront?.length > 0
    )

  const hasDuplicatedData = hasDuplicatedValuesInLegalRepresentativies(legalRepresentativiesWatch || [])

  const [updateLegalRepresentativies, { loading: updateLegalRepresentativiesIsLoading }] = useUpdateIndicationProposalUpdateLegalRepresentativeMutation({
    onCompleted(data) {
      const updatedLegalRepresentativies = data.updateIndicationProposalUpdateLegalRepresentative.customerRef.legalRepresentative
      if (updatedLegalRepresentativies && selectedProposal) {
        setSelectedProposal({ ...selectedProposal, customerRef: { ...selectedProposal.customerRef, legalRepresentative: updatedLegalRepresentativies } })
      }
      formMethods.clearErrors()
      toast.success('Os dados do representante legal foram salvos!')
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const onSearchCep = useCallback(async (index: number) => {
    if (legalRepresentativiesWatch) {
      const selectedRepresentativy = legalRepresentativiesWatch[index]
      if (selectedRepresentativy) {
        const { data, isSuccess } = await fetchCep(selectedRepresentativy.address?.zipcode || '')
        if (isSuccess) {
          formMethods.setValue(`legalRepresentativies.${index}.address.state`, data?.uf || '')
          formMethods.setValue(`legalRepresentativies.${index}.address.city`, data?.localidade || '')
          formMethods.setValue(`legalRepresentativies.${index}.address.neighborhood`, data?.bairro || '')
          formMethods.setValue(`legalRepresentativies.${index}.address.street`, data?.logradouro || '')
        }
      }
    }
  }, [legalRepresentativiesWatch])

  useEffect(() => {
    if (selectedProposal && selectedProposal.customerRef.legalRepresentative && selectedProposal.customerRef?.legalRepresentative.length > 0) {
      formMethods.setValue('legalRepresentativies', selectedProposal.customerRef.legalRepresentative as any || [])
      const fieldsToSetError: LegalRepresentativyPendency[] = [
        'city',
        'complement',
        'neighborhood',
        'number',
        'state',
        'street',
        'zipcode',
        'name',
        'document',
        'identificationNumber',
        'email',
        'phone',
        'maritalStatus',
        'nationality'
      ]

      selectedProposal.customerRef.legalRepresentative.map((representativy, i) => {
        const customerPjRepresentativeDocumentWithPhotoFront =
          Boolean(representativy?.files?.find(item => item.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront)?.rejectedAt)
        const customerPjRepresentativeDocumentWithPhotoBack =
          Boolean(representativy?.files?.find(item => item.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack)?.rejectedAt)

        if (customerPjRepresentativeDocumentWithPhotoFront) {
          formMethods.setError(`legalRepresentativies.${i}.customerPjRepresentativeDocumentWithPhotoFront`, { message: 'Este campo foi reprovado por um analista!' })
        }

        if (customerPjRepresentativeDocumentWithPhotoBack) {
          formMethods.setError(`legalRepresentativies.${i}.customerPjRepresentativeDocumentWithPhotoBack`, { message: 'Este campo foi reprovado por um analista!' })
        }

        fieldsToSetError.map(item => {
          if (representativy?.pendencies?.[item].rejectedAt) {
            if (item === 'city' || item === 'state' || item === 'complement' || item === 'neighborhood' || item === 'number' || item === 'street' || item === 'zipcode') {
              formMethods.setError(`legalRepresentativies.${i}.address.${item}`, { message: 'Este campo foi reprovado por um analista!' })

            } else {
              formMethods.setError(`legalRepresentativies.${i}.${item}`, { message: 'Este campo foi reprovado por um analista!' })
            }
          }
        })
      })

    } else {
      formMethods.setValue('legalRepresentativies', [LEGAL_REPRESENTATIVE_INITIAL_VALUES])
    }
  }, [])

  const handleSubmit = useCallback(async (data: FormData) => {

    await updateLegalRepresentativies({
      variables: {
        params: {
          id: selectedIndication?._id || '',
          legalRepresentatives: data.legalRepresentativies.map(representativy => {
            return {
              document: clearString(representativy.document),
              email: representativy.email,
              name: representativy.name,
              phone: clearString(representativy.phone),
              identificationNumber: representativy.identificationNumber,
              maritalStatus: representativy.maritalStatus,
              nationality: representativy.nationality,
              address: {
                country: 'Brasil',
                zipcode: clearString(representativy.address.zipcode),
                city: representativy.address.city,
                neighborhood: representativy.address.neighborhood,
                number: representativy.address.number,
                state: representativy.address.state,
                street: representativy.address.street,
                complement: representativy.address.complement,
              }
            }
          })
        }
      }
    })

    if (data.legalRepresentativies && data.legalRepresentativies.length > 0) {

      const allFilesUploaded = []

      for (let index = 0; index < data.legalRepresentativies.length; index++) {
        const selectedRepresentativy = data.legalRepresentativies[index]

        const filesToUpload: File[][] = []
        const fileTypes: EProposalFileType[] = []

        if (selectedRepresentativy.customerPjRepresentativeDocumentWithPhotoBack && selectedRepresentativy.customerPjRepresentativeDocumentWithPhotoBack.length > 0) {
          filesToUpload.push(selectedRepresentativy.customerPjRepresentativeDocumentWithPhotoBack)
          fileTypes.push(EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack)
        }
        if (selectedRepresentativy.customerPjRepresentativeDocumentWithPhotoFront && selectedRepresentativy.customerPjRepresentativeDocumentWithPhotoFront.length > 0) {
          filesToUpload.push(selectedRepresentativy.customerPjRepresentativeDocumentWithPhotoFront)
          fileTypes.push(EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront)
        }
        if (filesToUpload.length > 0 && fileTypes.length > 0) {
          const filesToUpdate = await uploadMultiFiles({
            types: fileTypes,
            files: filesToUpload,
          })

          allFilesUploaded.push({
            identificationNumber: clearString(selectedRepresentativy.document),
            files: filesToUpdate
          })
        }
      }

      if (allFilesUploaded.length > 0) {
        for (let index = 0; index < allFilesUploaded.length; index++) {
          const files = allFilesUploaded[index].files || []
          const representativyIdentification = allFilesUploaded[index].identificationNumber
          for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {

            await updateIndicationFiles({
              variables: {
                params: {
                  id: selectedIndication?._id || '',
                  type: files[fileIndex].type,
                  legalRepresentativeDocument: representativyIdentification,
                  file: {
                    fileName: files[fileIndex].file.fileName,
                    fileUrl: files[fileIndex].file.fileUrl,
                    key: files[fileIndex].file.key,
                    storageService: files[fileIndex].file.storageService,
                  }
                }
              },
              onCompleted(completedData) {
                const updatedLegalRepresentativies = completedData.updateIndicationProposalFiles.customerRef.legalRepresentative
                if (files.length - 1 === fileIndex && updatedLegalRepresentativies && selectedProposal) {
                  setSelectedProposal({ ...selectedProposal, customerRef: { ...selectedProposal.customerRef, legalRepresentative: updatedLegalRepresentativies } })
                  formMethods.setValue('legalRepresentativies', updatedLegalRepresentativies as any)
                  toast.success('Os documentos do representante legal foram salvos!')
                }

              },
              onError(error) {
                toast.error(error.message)
              },
            })
          }
        }
      }

    }

  }, [selectedIndication, selectedProposal])

  const onRemove = useCallback(async (index: number) => {
    if (legalRepresentativiesWatch) {
      const isConfirmed = await confirmDialog({
        title: 'Remoção de representante legal',
        type: 'warning',
        content: (
          <Box>
            <p>Esta ação irá remover o representante legal <b>{`${legalRepresentativiesWatch[index].name}`}</b> da proposta.</p>
            <p>Para salvar a informação de exclusão de um representante legal, você deverá clicar em {'"Confirmar"'} no rodapé da página após esta ação.</p>
          </Box>
        )
      })

      if (isConfirmed) {
        remove(index)
      }
    }
  }, [fields, legalRepresentativiesWatch])

  return (
    <>
      <FormProvider {...formMethods}>

        <form onSubmit={formMethods.handleSubmit((data) => handleSubmit(data))}>

          {fields.map((field, index) => {

            const customerPjRepresentativeDocumentWithPhotoFront = field?.files?.find(item => item.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoFront)
            const customerPjRepresentativeDocumentWithPhotoBack = field?.files?.find(item => item.type === EProposalFileType.customerPjRepresentativeDocumentWithPhotoBack)

            const canDelete =
              field?.pendencies?.name.acceptedAt &&
              field?.pendencies?.identificationNumber.acceptedAt &&
              field?.pendencies?.email.acceptedAt &&
              field?.pendencies?.phone.acceptedAt &&
              field?.pendencies?.maritalStatus.acceptedAt &&
              field?.pendencies?.nationality.acceptedAt &&
              field?.pendencies?.zipcode.acceptedAt &&
              field?.pendencies?.state.acceptedAt &&
              field?.pendencies?.city.acceptedAt &&
              field?.pendencies?.neighborhood.acceptedAt &&
              field?.pendencies?.street.acceptedAt &&
              field?.pendencies?.number.acceptedAt &&
              customerPjRepresentativeDocumentWithPhotoFront?.status === EProposalFileStatus.accepted &&
              customerPjRepresentativeDocumentWithPhotoBack?.status === EProposalFileStatus.accepted

            return (
              <Box key={field.id}>
                {fields.length > 1 &&
                  <Divider sx={{ margin: '2rem 0' }} />
                }

                <Typography sx={{ paddingBottom: '1rem' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Representante legal</Typography>
                <Grid sx={{ paddingBottom: '1rem' }} container spacing={3}>
                  <Grid xs={12} md={8} item>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('name', field?.pendencies)} label='Nome' name={`legalRepresentativies.${index}.name`} />
                  </Grid>

                  <Grid xs={12} md={4} item>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('document', field?.pendencies)} mask='cpf' label='CPF' name={`legalRepresentativies.${index}.document`} />
                  </Grid>

                  <Grid xs={12} md={4} item>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('identificationNumber', field?.pendencies)} label='RG' name={`legalRepresentativies.${index}.identificationNumber`} />
                  </Grid>

                  <Grid xs={12} md={4} item>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('email', field?.pendencies)} label='Email' name={`legalRepresentativies.${index}.email`} />
                  </Grid>

                  <Grid xs={12} md={4} item>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('phone', field?.pendencies)} mask='phone' label='Telefone' name={`legalRepresentativies.${index}.phone`} />
                  </Grid>

                  <Grid xs={12} md={4} item>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('nationality', field?.pendencies)} label='Nacionalidade' name={`legalRepresentativies.${index}.nationality`} />
                  </Grid>

                  <Grid xs={12} md={4} item>
                    <Select disabled={hasPendencyOrNullOnLegalRepresentativy('maritalStatus', field?.pendencies)} options={maritalStatusOptions} label='Estado civil' name={`legalRepresentativies.${index}.maritalStatus`} />
                  </Grid>

                </Grid>

                <Typography sx={{ paddingBottom: '1rem' }} variant='h5' fontWeight={500} color={theme.palette.grey[800]}>Endereço</Typography>
                <Grid container spacing={3}>

                  <Grid xs={12} md={4} item>
                    <Input
                      disabled={hasPendencyOrNullOnLegalRepresentativy('zipcode', field?.pendencies)}
                      icons={{
                        end: {
                          element: fetchCepIsLoading ?
                            <CircularProgress size={18} color='inherit' /> :
                            <IconButton disabled={hasPendencyOrNullOnLegalRepresentativy('zipcode', field?.pendencies) || hasEqualField} onClick={() => onSearchCep(index)}><IconSearch /></IconButton>
                        }
                      }}
                      mask='cep'
                      name={`legalRepresentativies.${index}.address.zipcode`}
                      label='CEP'
                    />
                  </Grid>

                  <Grid item xs={12} md={4} sx={{ paddingTop: '0 !important' }}></Grid>
                  <Grid item xs={12} md={4} sx={{ paddingTop: '0 !important' }}></Grid>

                  <Grid item xs={12} md={4}>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('street', field?.pendencies)} name={`legalRepresentativies.${index}.address.street`} label='Rua' />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('number', field?.pendencies)} name={`legalRepresentativies.${index}.address.number`} label='Número' />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('complement', field?.pendencies)} name={`legalRepresentativies.${index}.address.complement`} label='Complemento (Opcional)' />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('neighborhood', field?.pendencies)} name={`legalRepresentativies.${index}.address.neighborhood`} label='Bairro' />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Input disabled={hasPendencyOrNullOnLegalRepresentativy('city', field?.pendencies)} name={`legalRepresentativies.${index}.address.city`} label='Cidade' />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Select disabled={hasPendencyOrNullOnLegalRepresentativy('state', field?.pendencies)} name={`legalRepresentativies.${index}.address.state`} label='UF' options={brazilStates} />
                  </Grid>
                </Grid>

                <Typography sx={{ padding: '1.6rem 0 1rem 0' }} variant='h5' fontWeight={500} color={theme.palette.grey[800]}>Documento</Typography>

                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <DragAndDrop
                      fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 1MB)'
                      name={`legalRepresentativies.${index}.customerPjRepresentativeDocumentWithPhotoFront`}
                      label='Anexar RG ou CNH (Frente)'
                      $variant='secondary'
                      disabled={documentFileIsDisabled(customerPjRepresentativeDocumentWithPhotoFront)}
                      status={customerPjRepresentativeDocumentWithPhotoFront?.status as EProposalFileStatus}
                      sentAt={customerPjRepresentativeDocumentWithPhotoFront?.sendAt}
                      $completed={customerPjRepresentativeDocumentWithPhotoFront?.sendAt}
                      fileName={customerPjRepresentativeDocumentWithPhotoFront?.file?.fileName}
                      fileUrl={customerPjRepresentativeDocumentWithPhotoFront?.file?.fileUrl}
                      accepted={{
                        images: true,
                        pdf: false
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DragAndDrop
                      fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 1MB)'
                      name={`legalRepresentativies.${index}.customerPjRepresentativeDocumentWithPhotoBack`}
                      label='Anexar RG ou CNH (Verso)'
                      $variant='secondary'
                      disabled={documentFileIsDisabled(customerPjRepresentativeDocumentWithPhotoBack)}
                      status={customerPjRepresentativeDocumentWithPhotoBack?.status as EProposalFileStatus}
                      sentAt={customerPjRepresentativeDocumentWithPhotoBack?.sendAt}
                      $completed={customerPjRepresentativeDocumentWithPhotoBack?.sendAt}
                      fileName={customerPjRepresentativeDocumentWithPhotoBack?.file?.fileName}
                      fileUrl={customerPjRepresentativeDocumentWithPhotoBack?.file?.fileUrl}
                      accepted={{
                        images: true,
                        pdf: false
                      }}
                    />
                  </Grid>
                </Grid>

                <Box sx={{ marginTop: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {fields.length - 1 === index ? (
                    <Button
                      size='small'
                      color='secondary'
                      startIcon={<IconPlus />}
                      onClick={() => append(LEGAL_REPRESENTATIVE_INITIAL_VALUES)}
                    >
                      Adicionar representante
                    </Button>
                  ) : <div></div>}
                  {fields?.length > 1 && (
                    <Button
                      size='small'
                      color='error'
                      startIcon={<IconTrash />}
                      disabled={canDelete}
                      onClick={() => onRemove(index)}
                    >
                      Remover representante
                    </Button>
                  )}

                </Box>

              </Box>
            )
          })}

          <Divider sx={{ margin: '2rem 0' }} />

          {hasEqualField && (
            <Box sx={{ paddingBottom: '2rem' }}>
              <InfoBanner text='Os campos (RG, CPF, E-mail e Telefone) não podem repetir entre os representantes' type='warning' />
            </Box>
          )}

          <Box sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Link to='/app/indications'>
              <Button startIcon={<IconArrowLeft />} color='secondary'>
                Voltar
              </Button>
            </Link>
            {hasDuplicatedData ? (
              <Button
                disabled={true}
                type='submit'
              >
                Confirmar
              </Button>
            ) : (

              <Button
                disabled={updateLegalRepresentativiesIsLoading || loadingFilesUpload || updateIndicationFilesIsLoading || !enableSubmit || hasEqualField}
                type='submit'
                startIcon={updateLegalRepresentativiesIsLoading || loadingFilesUpload || updateIndicationFilesIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />}
              >
                Confirmar
              </Button>
            )}
          </Box>

        </form>
      </FormProvider>
    </>
  )
}
