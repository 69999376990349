import { useCallback, useEffect, useMemo } from 'react'
import { IconCheck, IconCircle, IconCirclePlus, IconEqual, IconInfoCircle } from '@tabler/icons-react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Box, Button, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { brazilStates } from '~/constants'
import { Form, Input, PageLoader, Radio, Select } from '~/components'
import { maritalStatusOptions, Option, personTypeOptions } from '~/utils/options'
import { usePartner } from '~/contexts/partner'
import { documentSchema, personTypeSchema, phoneSchema, ufSchema } from '~/utils/yupSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { EMaritalStatus, EPersonType, useCreateIndicationMutation } from '~/graphql/types'
import { clearString, formatPercentageToString, transformMoney } from '~/utils'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import { useDealershipsPartner } from '~/contexts'

type FormData = {
  personType: string,
  name: string,
  document: string,
  email: string,
  phone: string,
  uf: string,
  dealershipId: string,
  averageEnergyBillValueCents: number
  commissionPercentage: number
  maritalStatus: EMaritalStatus
  nationality: string;
}

export const IndicationsCreateForm: React.FC = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const { myPartner, getPartnerIsLoading } = usePartner()
  const { dealerships, dealershipsIsLoading } = useDealershipsPartner()

  const [onCreateIndication, { loading: createIndicationIsLoading }] = useCreateIndicationMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if (data.createIndication) {
        toast.success('A indicação foi registrada!')
        navigate(`/app/indications`)
      }
    },
  })

  const schema = yup.object({
    personType: personTypeSchema,
    name: yup.string().required(),
    document: documentSchema,
    email: yup.string().email().required(),
    phone: phoneSchema,
    uf: ufSchema,
    dealershipId: yup.string().required(),
    averageEnergyBillValueCents: yup.number().required(),
    commissionPercentage: yup.number()
      .test('percentage max', (value, { createError, parent }) => {
        const isPf = parent.personType === EPersonType.pf
        const yupDealership = dealerships.find(item => item.dealershipId === parent.dealershipId)
        const commissionType = myPartner?.partnershipConditions?.commissioningType
        const baseCommission = myPartner?.partnershipConditions?.baseCommissionPercentage || 0
        const discountMax = isPf ? yupDealership?.customerPFDiscountMax || 0 : yupDealership?.customerPJDiscountMax || 0
        if (value && yupDealership) {
          const ceilingPercentageForPartner: number = (discountMax) + (baseCommission)
          if (commissionType === 'anticipated' && value > discountMax) {
            return createError({
              message: `O percentual de economia não deve ultrapassar de ${discountMax}%`,
            })
          } else if (value > ceilingPercentageForPartner) {
            return createError({
              message: `O percentual de economia não deve ultrapassar de ${ceilingPercentageForPartner}%`,
            })
          } else {
            return true
          }

        } else {
          return true
        }
      })
      .test('percentage min', (value, { createError, parent }) => {
        const isPf = parent.personType === EPersonType.pf
        const yupDealership = dealerships.find(item => item.dealershipId === parent.dealershipId)
        const discountMin: number = isPf ? yupDealership?.customerPFDiscountMin || 0 : yupDealership?.customerPJDiscountMin || 0
        if (value && yupDealership) {
          if (value < (discountMin || 0)) {
            return createError({
              message: `O percentual de economia não deve ser menor que ${formatPercentageToString(discountMin || 0)}.`,
            })
          } else {
            return true
          }

        } else {
          return true
        }
      }).required(),
    maritalStatus: yup.string().test('required', (value, { createError, parent }) => {
      if (parent.personType === EPersonType.pf && !value) {
        return createError({
          message: 'Este campo é obrigatório!',
        })
      }
      return true
    }),
    nationality: yup.string().test('required', (value, { createError, parent }) => {
      if (parent.personType === EPersonType.pf && !value) {
        return createError({
          message: 'Este campo é obrigatório!',
        })
      }
      return true
    }),
  }).required()

  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      personType: EPersonType.pf,
      uf: '',
      commissionPercentage: 0,
      maritalStatus: '' as EMaritalStatus,
    }
  })

  const { uf, dealershipId, commissionPercentage, personType } = useWatch({ control: formMethods.control })
  const selectedDealership = dealerships.find(item => item.dealershipId === dealershipId)
  const isPf = personType === EPersonType.pf

  useEffect(() => {
    if (dealershipId) {
      // colocar PJ
      formMethods.setValue('commissionPercentage', isPf ? selectedDealership?.customerPFDiscountOffered || 0 : selectedDealership?.customerPJDiscountOffered || 0)
    }
  }, [dealershipId, personType])

  useEffect(() => {
    if (uf) {
      formMethods.setValue('commissionPercentage', 0)
      formMethods.setValue('dealershipId', '')
    }
  }, [uf])

  useEffect(() => {
    formMethods.clearErrors()
    formMethods.setValue('maritalStatus', '' as EMaritalStatus)
    formMethods.setValue('nationality', '')
    formMethods.setValue('document', '')
  }, [personType])

  const dealershipOptions: Option[] = useMemo(() => dealerships?.filter(dealership => dealership.uf === uf)
    .map(dealership => {
      return { value: dealership.dealershipId, label: dealership.dealershipName || '' }
    }), [dealerships, uf]) || []

  const onSubmit = useCallback((data: FormData) => {
    onCreateIndication({
      variables: {
        params: {
          averageEnergyBillValueCents: transformMoney(data.averageEnergyBillValueCents, 'toCents'),
          dealershipId: data.dealershipId,
          document: clearString(data.document),
          email: data.email,
          name: data.name,
          phone: clearString(data.phone),
          uf: data.uf,
          commissionPercentage: data.commissionPercentage,
          personType: data.personType as EPersonType,
          ...isPf && {
            maritalStatus: data.maritalStatus,
            nationality: data.nationality,
          }
        }
      }
    })
  }, [dealershipOptions])

  const filtersStates = useMemo(() => {
    return brazilStates.filter(state => state.value === dealerships.find(dealership => dealership.uf === state.value)?.uf)
  }, [myPartner, dealerships])

  const hasDealership = Boolean(dealershipId)
  const hasCommissionPercentage = Boolean(commissionPercentage)

  const discountMax = isPf ? selectedDealership?.customerPFDiscountMax : selectedDealership?.customerPJDiscountMax
  const discountMin = isPf ? selectedDealership?.customerPFDiscountMin : selectedDealership?.customerPJDiscountMin

  const comissionPercentageIsLowerToDiscountMax = (commissionPercentage || 0) <= (discountMax || 0)
  // const roofPercentage = (discountMax || 0) + (myPartner?.partnershipConditions?.baseCommissionPercentage || 0) // 23,5
  // const refTeto = selectedDealership?.ceilingReferencePercentage || 0
  const maxPercentage = Number((discountMax || 0) + (myPartner?.partnershipConditions?.baseCommissionPercentage || 0))
  // const teto = Number((discountMax || 0) + refTeto)
  // const absoluteDiference = Math.abs(Number((discountMax || 0) - (commissionPercentage || 0))) // 2
  // const calcProportion = 100 / Math.abs(refTeto) // 25%
  // const lossOfPartnerPercentage = Number(absoluteDiference) * Number(calcProportion) // 50
  // const calcbaseCommissionPercentage = Number((myPartner?.partnershipConditions?.baseCommissionPercentage || 0) / 100) // 0,035
  // const aditionalComission = comissionPercentageIsLowerToDiscountMax
  //   ? Number(Number((discountMax || 0) - (commissionPercentage || 0)).toFixed(2)) / 2
  //   : Number(calcbaseCommissionPercentage * lossOfPartnerPercentage * -1).toFixed(2) // 0,035 * 50 * -1 = -1,75
  // const aditionalComission = Number((myPartner?.partnershipConditions?.baseCommissionPercentage || 0) * lostFraction);

  // const lostFraction = discountDifference / (myPartner?.partnershipConditions?.baseCommissionPercentage || 0)
  const discountDifference = Number((discountMax || 0) - (commissionPercentage || 0));
  const aditionalComission = comissionPercentageIsLowerToDiscountMax
    ? Number(discountDifference / 2).toFixed(2)
    : discountDifference.toFixed(2)

  const finalComission = Number((myPartner?.partnershipConditions?.baseCommissionPercentage || 0) + Number(aditionalComission)).toFixed(2)

  if (dealershipsIsLoading) return <PageLoader />

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
          <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Tipo de pessoa:</Typography>

          <Radio row name='personType' options={personTypeOptions} />

          <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Dados</Typography>
          <Grid spacing={3} container>
            <Grid item md={8} xs={12}>
              <Input name='name' label='Nome completo' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input name='document' label={isPf ? 'CPF' : 'CNPJ'} mask={isPf ? 'cpf' : 'cnpj'} />
            </Grid>

            <Grid item md={4} xs={12}>
              <Input name='email' label='E-mail' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input name='phone' label='Telefone' mask='phone' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Select variant='outlined' name='uf' label='UF' options={filtersStates} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Select
                name='dealershipId'
                variant='outlined'
                options={dealershipOptions}
                disabled={getPartnerIsLoading}
                label='Concessionária de energia'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input name='averageEnergyBillValueCents' label='Valor médio de consumo' mask='BRL' />
            </Grid>
            <Grid item md={4} xs={12}>
            </Grid>
          </Grid>

          {isPf && (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Dados complementares</Typography>
                </Grid>
                <Grid xs={12} md={4} item>
                  <Input label='Nacionalidade' name='nationality' />
                </Grid>
                <Grid xs={12} md={4} item>
                  <Select label='Estado civil' name='maritalStatus' options={maritalStatusOptions} />
                </Grid>
              </Grid>
            </>
          )}

          <Grid container>

            <Grid item xs={12}>
              <Typography sx={{ padding: '0 0 1rem 0' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Percentual de economia</Typography>
            </Grid>

            <Grid item xs={12}>
              {Boolean(selectedDealership) && (
                <Box sx={{ display: 'flex', alignItems: 'start', gap: '.4rem', padding: '0 0 1.5rem 0' }}>
                  <IconInfoCircle color={theme.palette.info.dark} size={20} />
                  <Box>
                    {myPartner?.partnershipConditions?.commissioningType === 'recurring' ?
                      <>
                        <Typography sx={{ fontWeight: 400 }} variant='body2' color={'#434343'}>

                          O valor para a concessionária informada deve estar entre {discountMin}% e {maxPercentage}%*
                        </Typography>


                        <Typography variant='body3' color={'#999999'}>
                          *em que {discountMax}% é o valor máximo de desconto para o cliente <br />e os {(myPartner?.partnershipConditions?.baseCommissionPercentage || 0)}% restantes serão subtraídos da sua comissão.
                        </Typography >
                      </>
                      :
                      <Typography sx={{ fontWeight: 400 }} variant='body2' color={'#434343'}>
                        O valor para a concessionária informada deve estar entre {discountMin}% e {discountMax}%
                      </Typography>
                    }

                  </Box >
                </Box >
              )}

            </Grid>
            <Grid item md={4} xs={12}>
              <Input disabled={!hasDealership} name='commissionPercentage' label='Percentual de economia' mask='percentage' />
            </Grid>
          </Grid>

          <Box translate='no' sx={{ background: '#fafafa', border: `1px solid ${theme.palette.grey[300]}`, padding: '1.4rem', borderRadius: '.8rem' }}>
            <Typography sx={{ paddingBottom: '1rem' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Sua comissão</Typography>
            <Box sx={{ display: 'flex', gap: isLowerSm ? '1rem' : '2rem', alignItems: isLowerSm ? 'flex-start' : 'center', flexFlow: isLowerSm ? 'column' : 'row' }}>
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                <Typography variant='body2' color={theme.palette.grey[600]}>Comissão base</Typography>
                <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>{myPartner?.partnershipConditions?.baseCommissionPercentage}%</Typography>
              </Box>
              <IconCirclePlus color={theme.palette.secondary.main} size={18} />
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                <Typography variant='body2' color={theme.palette.grey[600]}>Comissão adicional</Typography>
                <Typography variant='h3' fontWeight={500} color={hasCommissionPercentage ? theme.palette.grey[800] : theme.palette.grey[300]}>{aditionalComission}%</Typography>
              </Box>
              <Box sx={{ position: 'relative' }}>
                <IconCircle color={theme.palette.secondary.main} size={18} />
                <IconEqual color={theme.palette.secondary.main} size={14} style={{ position: 'absolute', top: '2px', left: '2px' }} />
              </Box>
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                <Typography variant='body2' color={theme.palette.grey[600]}>Comissão final</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                  <IconCheck color={hasCommissionPercentage ? theme.palette.success.main : theme.palette.grey[300]} />
                  <Typography variant='h3' fontWeight={500} color={hasCommissionPercentage ? theme.palette.grey[800] : theme.palette.grey[300]}>{finalComission}%</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider />

          <Box sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Button color='secondary'>
              Cancelar
            </Button>

            <Button
              type='submit'
              disabled={createIndicationIsLoading}
              startIcon={createIndicationIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Form>
    </FormProvider>
  )
}
