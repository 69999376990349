import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { PartnerNews as PartnerNewsIcon } from '~/assets/illustrations'
import { usePartner } from '~/contexts'


export const PartnerNews: React.FC = () => {
  const { myPartner } = usePartner()
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={{
      gap: 3,
      padding: isLowerSm ? 2 : 4,
      display: 'flex',
      background: theme.palette.secondary.light,
      borderRadius: '12px',
      position: 'relative',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.secondary.main}`,
      flexGrow: 1
    }}>
      {!isLowerMd && <PartnerNewsIcon style={{ position: 'absolute', top: '32px', right: '32px', fontSize: 200 }} />}

      <Typography variant='h3' color={theme.palette.grey[700]} fontWeight={600}>
        Temos uma novidade, parceiro 🤩
      </Typography>
      <Box sx={{ display: 'flex', flexFlow: 'column' }}>
        <Typography sx={{ marginTop: 1, lineHeight: isLowerSm ? '150%' : '30px', maxWidth: '460px', fonbtSize: '18px' }}>
          Agora, ao divulgar seu link único para captar clientes, você pode usar a sua nova página da Desperta!
        </Typography>
        <Typography sx={{ marginTop: 1, lineHeight: isLowerSm ? '150%' : '30px', maxWidth: '480px', fonbtSize: '18px' }}>
          Ela é mais completa e conta com informações que podem lhe ajudar em suas vendas.
        </Typography>
        <Typography sx={{ marginTop: 1, lineHeight: isLowerSm ? '150%' : '30px', maxWidth: '460px', fonbtSize: '16px' }}>
          Caso prefira a versão anterior, ela continuará disponível.
        </Typography>
      </Box>
      <Box sx={{  display: 'flex', flexFlow: 'column', maxHeight: '50px' }}>
        <Link to={`${process.env.VITE_APP_WEBSITE_BASE_URL}/solicitar-proposta/${myPartner?.code}`} target='_blank'>
          <Button color='purple'>ACESSAR NOVA PÁGINA</Button>
        </Link>
      </Box>
    </Box>
  )
}
