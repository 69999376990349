'use client'
import { cloneElement, ReactElement, ReactNode, useRef } from 'react'
import { useOnClickOutside, useToggle } from '~/hooks'
import { Container, Drop } from './styles'

type DropdownProps = {
  elementClick: ReactElement<React.ComponentPropsWithoutRef<"div">>,
  children: ReactNode,
  customClose?: Function
  $options?: {
    top?: string,
    left?: string
  }
}

export const Dropdown: React.FC<DropdownProps> = ({ children, elementClick, $options, customClose }) => {
  const ref = useRef(null)
  const { toggle, isTrue, setIsTrue } = useToggle()

  //verify clickOutside Dropdown
  useOnClickOutside(ref, () => (setIsTrue(false), customClose && customClose()))

  const onClose = () => {
    toggle()
    customClose && customClose()
  }

  return (
    <Container $isTrue={isTrue} ref={ref}>
      {cloneElement(elementClick, { onClick: onClose })}
      {isTrue && (
        <Drop $isTrue={isTrue} $options={$options} >
          {children}
        </Drop>
      )}
    </Container>
  )
}
