import { InputHTMLAttributes, useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorText } from '../ErrorText'
import { Container, ContainerDrop, ContentDrop, RenderFiles } from './styles'
import { formatDate } from '~/utils'
import { Box, Typography } from '@mui/material'
import { IconCheck, IconDownload, IconEye, IconPlane, IconX } from '@tabler/icons-react'
import { EProposalFileStatus } from '~/graphql/types'
import { Link } from 'react-router-dom'

type DragAndDropProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  label: string
  fileDescription: string,
  fileSizeText?: string
  $variant?: 'primary' | 'secondary'
  $completed?: boolean,
  sentAt?: string | null
  fileName?: string
  fileKey?: string
  status?: string | null
  fileUrl?: string
  canResend?: boolean
  accepted?: {
    images: boolean
    pdf: boolean
  }
}

export const DragAndDrop: React.FC<DragAndDropProps> = ({ name, accepted, fileKey, label, fileDescription, fileSizeText, $variant = 'primary', $completed, sentAt, fileName, status, disabled = false, fileUrl, canResend, ...rest }) => {
  const { control, setValue, clearErrors } = useFormContext()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    clearErrors(name)
    setValue(name, acceptedFiles, { shouldValidate: true })
  }, [name, setValue, clearErrors])

  const { getRootProps, getInputProps, isDragActive, acceptedFiles }: any = useDropzone({ onDrop, 
    accept: {
      ...accepted?.images ? { 'image/jpeg': ['.jpeg', '.png'] } : {},
      ...accepted?.images ? { 'image/png': ['.png'] } : {},
      ...accepted?.pdf ? { 'application/pdf': ['.pdf'] } : {},
    }
   })

  const onDelete = useCallback((file: File) => {
    const newFiles = [...acceptedFiles]
    newFiles.splice(acceptedFiles.indexOf(file), 1)
    setValue(name, newFiles, { shouldValidate: true })
  }, [acceptedFiles, name, setValue])

  const isRejected = status === EProposalFileStatus.rejected

  const colorIcon = useCallback(({ isValid, hasError }: { isValid: boolean, hasError: boolean }) => {
    if (isRejected) return 'red'
    if (disabled) return '#777'
    if ($variant === 'secondary') return '#7ABFFF'
    if (hasError) return 'red'
    if (isValid) return 'green'
    return '#777'
  }, [])

  const getStatusInfo = () => {
    switch (status) {
      case EProposalFileStatus.accepted:
        return { color: '#00A86B', text: 'Aprovado' }
      case EProposalFileStatus.rejected:
        return { color: '#EF4444', text: 'Negado' }
      case EProposalFileStatus.send:
        return { color: '#3BA1FF', text: 'Em análise' }
      default:
        return { color: '', text: '' }
    }
  }

  const statusInfo = getStatusInfo()
  const isDisabled = useMemo(() => {
    if (isRejected) {
      return false
    }

    return !canResend && ($completed || disabled)
  }, [$completed, disabled, isRejected])

  return (
    <Controller
      defaultValue={[]}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Container>
          <div {...getRootProps({
            onClick: (event: any) => disabled ? event.stopPropagation() : null
          })}>
            <input
              multiple
              {...field}
              value={field.value && field.value.filename}
              onChange={(event) => field.onChange(event.target.files)}
              {...getInputProps()}
              disabled={isDisabled}
              {...rest}
            />
            <ContainerDrop
              $isRejected={isRejected}
              disabled={disabled}
              $completed={$completed}
              $variant={$variant}
              $qtdFiles={acceptedFiles.length}
              $isValid={acceptedFiles.length > 0}
              $hasError={Boolean(error?.message)}
              $isDragActive={isDragActive}
            >
              {(!$completed || isRejected) && (
                <IconDownload
                  color={colorIcon({ isValid: acceptedFiles.length > 0, hasError: Boolean(error?.message) })}
                  size={48}
                />
              )}

              {(!$completed || isRejected) && (
                <ContentDrop disabled={isDisabled} $completed={$completed} $variant={$variant}>
                  <strong>{label}</strong>
                  <span>{fileDescription}</span>
                  {acceptedFiles.length < 1 && fileSizeText && <span>{fileSizeText}</span>}
                  <RenderFiles>
                    {acceptedFiles.map((file: any) => {
                      return <li key={file.name}> <IconCheck size={18} /> <span> {file.name} </span> <button onClick={() => onDelete(file)}>remover <IconX style={{ color: 'red' }} /></button></li>
                    })}
                  </RenderFiles>

                  {isRejected && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                      <strong>{fileName}</strong>
                      {status && <Typography color={statusInfo.color}>{statusInfo.text}</Typography>}
                    </Box>
                  )}
                </ContentDrop>
              )}

              {$completed && !isRejected && (
                <ContentDrop disabled={isDisabled} $completed={$completed} $variant={$variant}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                    {fileName && <strong>{fileName}</strong>}
                    {status && <Typography color={statusInfo.color}>{statusInfo.text}</Typography>}
                  </Box>
                  {sentAt && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <IconPlane />
                      <span>Enviado em {formatDate(sentAt)}</span>
                    </Box>
                  )}
                </ContentDrop>
              )}

            </ContainerDrop>
          </div>

          {fileUrl && <Link to={fileUrl} target='_blank' className='eyeIcon'><IconEye size={32} /></Link>}
          {error?.message && <ErrorText error={error?.message} />}
        </Container>
      )}
    />
  )
}
